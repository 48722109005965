import FaqCollapse from "./reusable/FaqCollapse";

const Faq = () => {
    return <>
    <div className="faq">
        <div className="container content" style={{paddingTop: "3rem", paddingBottom: "3rem", zIndex: 50}}>
            <h1 className="text-center mb-5">Frequently asked questions</h1>
            <div className="d-md-flex justify-content-center flex-wrap space-y-4">
                <FaqCollapse
                id="faq2"
                title="De ce ați ales RAGE Multiplayer și nu FiveM?"
                content="Din punctul nostru de vedere, RAGE Multiplayer este cel mai stabil și performant în acest moment. FiveM ne-ar fi limitat, astfel, să realizăm tot ceea ce ne-am propus."
                />
                <FaqCollapse
                id="faq3"
                title="Contează dacă am jocul pe Steam sau pe Epic Games?"
                content="Nu, RAGE Multiplayer este disponibil pe Rockstar Games Launcher, Steam și Epic Games. Nu puteți juca fără o licență valida (fără să aveți jocul cumpărat)."
                />
                <FaqCollapse
                id="faq4"
                title="Cum va fi modul de joc?"
                content="Fără absolut niciun dubiu, RPG, asemănător cu cel de pe serverele de San Andreas Multiplayer. Servere roleplay de Grand Theft Auto V sunt deja foarte multe, iar noi ca dezvoltatori preferăm RPG peste Roleplay."
                />
                <FaqCollapse
                id="faq5"
                title="Ce ne putem aștepta să găsim pe server?"
                content="O experiență asemănătoare că pe un server RPG de San Andreas Multiplayer, dar mult mai îmbunătățită."
                />
                <FaqCollapse
                id="faq6"
                title="Cum va fi economia de pe server?"
                content="În acest moment, nu putem oferi un răspuns concret la această întrebare."
                />
                <FaqCollapse
                id="faq7"
                title="Cum pot ajuta serverul?"
                content="Te așteptăm pe serverul de Discord, acolo vei avea toate detaliile."
                />
                <FaqCollapse 
                id="faq8"
                title="Ne putem aștepta la mașini custom pe server?"
                content="Da, dar puține și optimizate. Suntem foarte atenți la aceste detalii pentru a asigura o calitate bună pentru fiecare jucător."
                />
                <FaqCollapse
                id="faq9"
                title="Va exista pe site User Control Panel si Forum?"
                content="Da, dar momentan prioritatea noastră este pe server."
                />
                <FaqCollapse
                id="faq10"
                title="Când va fi deschis serverul?"
                content="Sperăm că vom reuși să-l terminăm până în Q1 2025, dar răspunsul cel mai sincer este: când va fi gata."
                />
            </div>
        </div>
    </div>
    </>
}

export default Faq;