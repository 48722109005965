import Preview from './assets/about.mp4'

const About = () => {
    return <>
    <div className="about">
        <div className="d-none d-md-block">
            <video autoPlay muted id="bg-video">
                <source src={Preview} type="video/mp4" />
            </video>
        </div>
        <div className="container mt-20 mb-10">
            <h1 className="text-center">Ce este Vinewood Multiplayer?</h1>

            <div className="mt-20 about-text">
                <p>
                Cu o pasiune pentru nostalgie și inovație, Vinewood Multiplayer este pe cale 
                să revoluționeze modul în care experimentați Grand Theft Auto V.<br/><br/>
                Acest proiect își propune să deschidă noi orizonturi în ceea ce privește<br/> modul de joc RPG.<br/><br/>
                Serverul va fi lansat pe platforma RAGE Multiplayer, o platformă care<br/> ne permite să realizăm tot ceea ce ne-am propus încă de la schițarea <br/>proiectului.
                </p>
            </div>
        </div>
    </div>
    </>
}

export default About;