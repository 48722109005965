import Logo from './assets/logo.svg'

const Redirect = () => {
    return <>
    <meta httpEquiv="refresh" content="0; URL=https://discord.com/invite/8Uw4wjc7nW" />
    <div className="redirect">
        <div className="container text-center text-white space-y-10 pt-5">
            <img src={Logo} className="img-fluid" alt="Vinewood Multiplayer" />
            <h2 style={{fontSize: "2rem"}}>Please wait, we are redirecting you!</h2>
            <p style={{fontSize: "1.5rem"}}>If you are not redirected, <a href="https://discord.com/invite/8Uw4wjc7nW" style={{color: "#A379C9"}}>click here</a>.</p>
        </div>
    </div>
    </>
}

export default Redirect;