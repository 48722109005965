
/*
            <div className="timeline d-inline-flex d-none d-md-flex justify-content-center align-items-center z-10">
                <div className="z-10 w-72 space-y-4 d-flex flex-wrap">
                    <h3 className="ml-8 w-52 h-16">The first phase of the server in which our team discovered all the possibilities to grow into a good RPG server.</h3>
                    <div className="ml-12 bg-purple-500 rounded-circle w-5 h-5"></div>
                    <h1 className="w-100 h-16">Pre-Alpha Release</h1>
                </div>
                <div className="z-10 w-72 space-y-4 d-flex flex-wrap">
                    <h3 className="ml-8 w-52 h-16">The first phase of the server in which our team discovered all the possibilities to grow into a good RPG server.</h3>
                    <div className="ml-12 bg-purple-500 rounded-circle w-5 h-5"></div>
                    <h1 className="w-100 h-16">Pre-Alpha Release</h1>
                </div>
                <div className="z-10 w-72 space-y-4 d-flex flex-wrap">
                    <h3 className="ml-8 w-52 h-16">The first phase of the server in which our team discovered all the possibilities to grow into a good RPG server.</h3>
                    <div className="ml-12 bg-purple-500 rounded-circle w-5 h-5"></div>
                    <h1 className="w-100 h-16">Pre-Alpha Release</h1>
                </div>
                <div className="z-10 w-72 space-y-4 d-flex flex-wrap">
                    <h3 className="ml-8 w-52 h-16">The first phase of the server in which our team discovered all the possibilities to grow into a good RPG server.</h3>
                    <div className="ml-12 bg-purple-500 rounded-circle w-5 h-5"></div>
                    <h1 className="w-100 h-16">Pre-Alpha Release</h1>
                </div>
            </div>

*/

const Roadmap = () => {
    return <>
    <div className="roadmap" id="roadmap">
        <div className="container space-y-24 z-20" style={{paddingTop: "3rem", paddingBottom: "3rem"}}>
            <h1 className="text-center">Vinewood Roadmap</h1>
            <div className="d-flex justify-content-center xl:hidden">
                <div className="timeline-vertical space-y-10 z-10">
                    <div className="right">
                        <div className="bg-purple-500 p-2 rounded w-60">
                            <h2 className="fw-bold">Pre-Alpha Stage</h2>
                            <h3 className="fw-normal">În această etapă inițială, serverul este testat intern de către echipa de dezvoltare. Se testează funcționalități de bază și concepte de joc.</h3>
                        </div>
                    </div>
                    <div className="left">
                        <div className="bg-purple-500 p-2 rounded w-60">
                            <h2 className="fw-bold">Alpha Stage</h2>
                            <h3 className="fw-normal">În această fază, serverul este lansat pentru un grup restrâns de testeri selectați. Se colectează feedback-ul testerilor pentru a face îmbunătățiri suplimentare.</h3>
                        </div>
                    </div>
                    <div className="right">
                        <div className="bg-purple-500 p-2 rounded w-60">
                            <h2 className="fw-bold">Beta Stage</h2>
                            <h3 className="fw-normal">Serverul este lansat pentru o bază mai largă de jucători. Scopul principal este de a identifica și rezolva erori și probleme de performanță într-un mediu mai complex și dinamic.</h3>
                        </div>
                    </div>
                    <div className="left">
                        <div className="bg-purple-500 p-2 rounded w-60">
                            <h2 className="fw-bold">Pre-Release Stage</h2>
                            <h3 className="fw-normal">În această etapă, serverul este gata să fie lansat publicului larg, dar mai întâi este supus unui ultim test intensiv. Se focalizează pe asigurarea că toate sistemele funcționează corect și că experiența de joc este cât mai plăcută și lipsită de probleme posibile.</h3>
                        </div>
                    </div>
                    <div className="right">
                        <div className="bg-purple-500 p-2 rounded w-60">
                            <h2 className="fw-bold">Release Stage</h2>
                            <h3 className="fw-normal">Momentul în care serverul este complet finalizat și gata de lansare publicului larg.</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="roadmap-city mt-[-1rem]"></div>
    </>
}

export default Roadmap;