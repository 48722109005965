import Hero from './Hero.jsx'
import Roadmap from './Roadmap.jsx'
import About from './About.jsx'
import Gallery from './Gallery.jsx'
import Faq from './FAQ.jsx'
import Footer from './Footer.jsx'

const App = () => {
    return <>
    <Hero />
    <Roadmap />
    <About />
    <Gallery />
    <Faq />
    <Footer />
    </>
}

export default App;