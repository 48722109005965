import Logo from './assets/logo.svg'

function openDiscordBtn() {
    window.gtag('event', 'conversion', {'send_to': 'AW-11228352109/qDZsCKC5l6cZEO2cjOop'});
    window.open("https://vinewood.ro/invite", "popup", "width=600, height=1000");
    return false;
}

const Hero = () => {
    return <>
    <div className="hero">
        <div className="container z-20 mb-20" style={{paddingTop: "5rem"}}>
            <div className="d-md-none">
                <div className="align-items-center">
                <div className="d-flex justify-content-center mb-2">
                        <img src={Logo} className="img-fluid" alt="Vinewood Multiplayer" />
                    </div>
                    <div className="text-center">
                        <h1>Vinewood Multiplayer</h1>
                        <h2>Romanian RPG Server</h2>

                        <h2 className="mt-4">on <span className="grey-text">RAGE Multiplayer</span></h2>
                        <h2>for <span className="grey-text">Grand Theft Auto V</span></h2>
                        <div className="d-inline-flex gap-3 align-items-center my-4">
                            <a className="button" onClick={openDiscordBtn}><i className="fa-brands fa-discord"></i> Discord</a>
                            <a href="https://www.youtube.com/@vinewoodmp" target="_blank"><i className="fa-brands fa-youtube social"></i></a>
                            <a href="https://instagram.com/vinewood.mp" target="_blank"><i className="fa-brands fa-instagram social"></i></a>
                            <a href="https://tiktok.com/@vinewood.mp" target="_blank"><i className="fa-brands fa-tiktok social"></i></a>
                        </div>
                        <h2>Launching Q1 2025. <a href="#roadmap" className="grey-text">View our Roadmap.</a></h2>
                    </div>
                </div>
            </div>
            <div className="d-none d-md-block">
                <div className="d-flex justify-content-between align-items-center">
                    <div className="flex-wrap">
                        <h1>Vinewood Multiplayer</h1>
                        <h2>Romanian RPG Server</h2>

                        <h2 className="mt-4">on <span className="grey-text">RAGE Multiplayer</span></h2>
                        <h2>for <span className="grey-text">Grand Theft Auto V</span></h2>
                        <div className="d-inline-flex gap-3 align-items-center my-4">
                            <a className="button" onClick={openDiscordBtn}><i className="fa-brands fa-discord"></i> Discord</a>
                            <a href="https://www.youtube.com/@vinewoodmp" target="_blank"><i className="fa-brands fa-youtube social"></i></a>
                            <a href="https://instagram.com/vinewood.mp" target="_blank"><i className="fa-brands fa-instagram social"></i></a>
                            <a href="https://tiktok.com/@vinewood.mp" target="_blank"><i className="fa-brands fa-tiktok social"></i></a>
                        </div>
                        <h2>Launching Q1 2025. <a href="#roadmap" className="grey-text">View our Roadmap.</a></h2>
                    </div>
                    <div className="d-flex justify-content-end">
                        <img src={Logo} alt="Vinewood Multiplayer" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
}

export default Hero;