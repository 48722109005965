import axios from 'axios';
import swal from 'sweetalert';

const NewsletterButton = async () => {
    const mailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const emailValue = document.getElementById("email").value;
    if(!mailRegex.test(emailValue)) {
        swal({
            title: "Oops!",
            icon: "error",
            text: "Trebuie sa introduci un email valid."
        });
        return false;
    }
    await axios({
        method: "POST",
        url: "https://api.vinewood.ro/newsletter/email",
        data: {
            email: emailValue
        }
    }).then(data => {
        if(data.data.success == true) {
            swal({
                title: "Succes!",
                icon: "success",
                text: "Ti-am inregistrat email-ul."
            });
        } else {
            swal({
                icon: "warning",
                text: "Adresa de email este deja inregistrata."
            });
        }
    }).catch(error => {
        if(error.response) {
            if(error.response.status == 429) {
                swal({
                    title: "Oops!",
                    icon: "error",
                    text: "Se pare ca ai facut prea multe request-uri."
                });
            } else {
                swal({
                    title: "Oops!",
                    icon: "error",
                    text: "Se pare ca avem o problema cu serverul, te rugam incearca mai tarziu."
                });
            }
        }
        if(error.request) {
            console.log('Error', error.message);
        }
    });
}

const Newsletter = () => {
    return <>
        <div className="d-flex justify-content-center mt-4">
            <div className="flex-wrap text-center">
                <h2>Nu rata nimic!</h2>
                <h3 className="fw-normal">Abonează-te la newsletter pentru a fi la curent cu toate actualizările.</h3>
                <div className="input-group mb-3">
                    <div className="form-icon"><i className="fa-solid fa-envelope fa-2x"></i></div>
                    <input type="email" id="email" className="form-control" />
                    <button className="btn btn-send" type="button" onClick={NewsletterButton}><i className="fa-solid fa-paper-plane fa-2x"></i></button>
                </div>
            </div>
        </div> 
    </>
}

export default Newsletter