import PropTypes from 'prop-types'

const FaqCollapse = (props) => {
    return<>
    <div className="flex-wrap">
        <button className="btn btn-faq" type="button" data-bs-toggle="collapse" data-bs-target={'#' + props.id} aria-expanded="false" aria-controls={props.id}>
            <i className="fa-solid fa-question-circle"></i> {props.title}
        </button>
        <div className="collapse" id={props.id}>
            <div className="card card-body" dangerouslySetInnerHTML={{__html: props.content}} />
        </div>
    </div>
    </>
}

FaqCollapse.propTypes = {
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired
}

export default FaqCollapse;