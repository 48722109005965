import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import './assets/index.css'
import React from 'react'
import { BrowserRouter, Switch, Route } from "react-router-dom";
import ReactDOM from 'react-dom/client'
import App from './App';
import Redirect from './Redirect';

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <BrowserRouter>
      <Switch>
        <Route exact path="/">
          <App />
        </Route>
        <Route path="/invite">
          <Redirect />
        </Route>
      </Switch>
    </BrowserRouter>
  </React.StrictMode>,
)
