import Image from './assets/gallery.png';
import Image2 from './assets/gallery2.png';
import Image3 from './assets/gallery3.png';
import Image4 from './assets/gallery4.png';
import Image5 from './assets/gallery5.png';
import Image6 from './assets/gallery6.png';
import Image7 from './assets/gallery7.png';
import Image8 from './assets/gallery8.png';
import Image9 from './assets/gallery9.png';
import Newsletter from './reusable/Newsletter';

const Gallery = () => {
    return <>
    <div className="gallery">
        <div className="container mx-auto mt-12 z-20 content">
            <div className="row gy-4">
                <div className="col-md-4">
                    <img src={Image} loading="lazy" alt="Vinewood Image Gallery 1" />
                </div>
                <div className="col-md-4">
                    <img src={Image2} loading="lazy" alt="Vinewood Image Gallery 2" />
                </div>
                <div className="col-md-4">
                    <img src={Image3} loading="lazy" alt="Vinewood Image Gallery 3" />
                </div>
                <div className="col-md-4">
                    <img src={Image4} loading="lazy" alt="Vinewood Image Gallery 4" />
                </div>
                <div className="col-md-4">
                    <img src={Image5} loading="lazy" alt="Vinewood Image Gallery 5" />
                </div>
                <div className="col-md-4">
                    <img src={Image6} loading="lazy" alt="Vinewood Image Gallery 6" />
                </div>
                <div className="col-md-4">
                    <img src={Image7} loading="lazy" alt="Vinewood Image Gallery 7" />
                </div>
                <div className="col-md-4">
                    <img src={Image8} loading="lazy" alt="Vinewood Image Gallery 8" />
                </div>
                <div className="col-md-4">
                    <img src={Image9} loading="lazy" alt="Vinewood Image Gallery 9" />
                </div>
            </div>

            <Newsletter />
        </div>
    </div>
    </>
}

export default Gallery;