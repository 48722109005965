import Logo from './assets/logo.svg';

const Footer = () => {
    return <>
        <div className="footer">
            <div className="d-md-flex justify-content-between align-items-center">
                <div>
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="me-3 bg-white rounded-5">
                            <img src={Logo} className="pe-2 ps-2" alt="Vinewood Favicon" />
                        </div>
                        <div className="text-uppercase footer-logo">Vinewood<br/>Multiplayer</div>
                    </div>
                </div>
                <div className="mt-2">
                    <div className="row">
                        <div className="col-md-3">
                            <ul className="d-inline-flex d-md-block">
                                <li><a href="https://ucp.vinewood.ro/">Ucp</a></li>
                                <li><a href="https://ucp.vinewood.ro/">Wiki</a></li>
                                <li><a href="https://ucp.vinewood.ro/">Forum</a></li>
                                <li><a href="https://ucp.vinewood.ro/">Shop</a></li>
                            </ul>
                        </div>
                        <div className="col-md-3">
                            <ul className="d-none d-md-block">
                                <li><a href="https://discord.com/invite/8Uw4wjc7nW"><i className="fa-brands fa-discord"></i> Discord</a></li>
                                <li><a href="https://www.youtube.com/@vinewoodmp"><i className="fa-brands fa-youtube"></i> Youtube</a></li>
                                <li><a href="https://tiktok.com/@vinewood.mp"><i className="me-1 fa-brands fa-tiktok"></i> Tiktok</a></li>
                                <li><a href="https://instagram.com/vinewood.mp"><i className="me-1 fa-brands fa-instagram"></i> Instagram</a></li>
                            </ul>
                        </div>
                        <div className="col-md-5 copyright">
                            GRAND THEFT AUTO AND GRAND THEFT AUTO: V ARE REGISTERED TRADEMARKS OF TAKE-TWO INTERACTIVE SOFTWARE.<br/>WE ARE NOT AFFILIATED WITH OR ENDORSED BY TAKE-TWO INTERACTIVE SOFTWARE.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default Footer;